<template>
  <div>loading....</div>
</template>

<script>
export default {
  name: "LoadingPage"
}
</script>

<style>

</style>